import axios from 'axios';
import { logout } from '../slices/auth-slice';
import store from '../store';
import { hideLoading } from '../slices/loading-slice';

// Create history object

// Interceptor setup
axios.interceptors.response.use(
  (response) => {
    console.log("axios response-------", response.data);
    if (response.data.message === 'UNAUTHENTICATED_USER' || 
        response.data.message === 'CREDENTIALS_INCORRECTS') {
      
      // Dispatch logout action so that we will capture it on the authMiddleware
      store.dispatch(logout());
      store.dispatch(hideLoading())
      if (response.data.message === 'CREDENTIALS_INCORRECTS') {
        Promise.reject('User not authenticated');
      }else{
        // Reject the promise to stop the execution so that we will handle the navigation 
        return Promise.reject('User not authenticated');
      }
      
    }
    
    return response;
  },
  (error) => {    
    if (error.response?.data === 'UNAUTHENTICATED_USER' || 
        error.response?.status === 401) {
      
      // Dispatch logout action so that we will capture it on the authMiddleware
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);