import { useSignOut } from "react-auth-kit";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthMiddleware = ({ children }) => {
  console.log("middleware -----------------");

  const isAuthenticated = useSelector(state => state.auth.isLoggedIn);
  const signOut = useSignOut();
  console.log(isAuthenticated);
  
  if (!isAuthenticated) {
    console.log("middleware not authenticated-----------------");
    signOut();
    return <Navigate to="/login" replace />;
  }

  return children;
};
export default AuthMiddleware;
