import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    paymentAmount: 0,
    payer_first_name: "",
    payer_last_name: "",
    receiving_merchant_code: 0,
    receiving_merchant_name: "",
    serviceDesc:"",
    current_step: 0,
    paymentDetails: {},
    initForm: 0,
    dsdService: null
}

export const MerchantPaymentSlice = createSlice({
    name: 'merchant-payment-authSlice',
    initialState: initialState,
    reducers: {
        setPaymentAmount(state, action) {
            state.paymentAmount = action.payload;
        },
        setPayerFirstName(state, action) {
            state.payer_first_name = action.payload;
        },
        setPayerLastName(state, action) {
            state.payer_last_name = action.payload;
        },
        setReceivingMerchantCode(state, action){
            state.receiving_merchant_code = action.payload;
        },
        setReceivingMerchantName(state, action) {
            state.receiving_merchant_name = action.payload;
        },
        setReceivingService(state, action) {
            state.serviceDesc = action.payload;
        },
        setCurrentStep(state, action) {
            state.current_step = action.payload;
        },
        setPaymentDetails(state, action) {
            state.paymentDetails = action.payload;
        },
        setInitForm(state, action) {
            state.initForm = action.payload;
        },
        setDsdService(state, action) {
            state.dsdService = action.payload;
        }
        
    }
});

// export the actions
export const { setPaymentAmount, setPayerFirstName, setPayerLastName, setReceivingMerchantCode,
     setReceivingMerchantName, setCurrentStep, setPaymentDetails, setInitForm, setDsdService, setReceivingService } = MerchantPaymentSlice.actions;

// export the reducer
export default MerchantPaymentSlice;